document.addEventListener('DOMContentLoaded', () => {
  const burger = document.querySelector('.header__burger');
  const menu = document.querySelector('.main-menu');
  const headerCta = document.querySelector('.header__cta');
  const overlay = document.querySelector('.header__overlay');

  menu.classList.add('processed');
  headerCta.classList.add('processed');

  burger.addEventListener('click', () => {
    menu.classList.add('opened');
    headerCta.classList.add('opened');
  });

  overlay.addEventListener('click', () => {
    menu.classList.remove('opened');
    headerCta.classList.remove('opened');
  });
});
